import { createContext, useEffect, useState, useContext } from "react";
import { useApi, baseURL, versaoClienteDTO, baseUrlAssets } from "./api";
import axios from "axios";
import {
  faSearch,
  faChartPie,
  faFileLines,
  faMagnifyingGlass,
  faCommentsDollar,
  faUtensils,
  faFileContract,
  faUser,
  faLaptop,
  faSquarePlus,
  faLock,
  faPeopleGroup,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useIsDev from "../utils/useIsDev";
import { useAuth } from "./auth";

export const MenuContext = createContext({});

export const MenuProvider = ({ children }) => {
  const navigate = useNavigate();
  const { get, post, put, token } = useApi();
  const { isDev } = useIsDev();
  const { homologacao } = useAuth();
  const url = "http://homolog.erp.waybe.com.br";
  const sessao = JSON.parse(localStorage.getItem("sessao"));
  // const { permissoes } = JSON.parse(localStorage.getItem("permissoes"));

  let arrayContratosAtivos;
  let arrayListaPrivilegios;
  let tipoPerfilUsuario;
  let tipoEmpresa;
  let permissoesWaybe;

  if (sessao) {
    arrayContratosAtivos = sessao.contratoWaychefAtualDTO.listaItens;
    permissoesWaybe = sessao?.contratoWaybeAtualDTO?.permissoesWaybe;
    arrayListaPrivilegios =
      sessao?.usuarioLogadoDTO?.usuarioPerfilEmpresaDTO?.permissoes;
    tipoPerfilUsuario =
      sessao?.usuarioLogadoDTO?.usuarioPerfilEmpresaDTO?.tipoPerfilUsuario;
    tipoEmpresa = sessao?.empresaDTO?.tipoEmpresa;
  }

  const tipoUsuario = JSON.parse(localStorage.getItem("_da"))?.usuarioLogadoDTO
    ?.usuarioPerfilEmpresaDTO?.tipoPerfilUsuario;

  const listaPrivilegios =
    JSON.parse(localStorage.getItem("_da"))?.usuarioLogadoDTO
      ?.usuarioPerfilEmpresaDTO?.listaPrivilegios ?? {};

  const permitirVisualizarMenuCompras =
    tipoUsuario === "MASTER" ||
    typeof listaPrivilegios?.PED_COMPRAS_SUMARIZADO === "string" ||
    typeof listaPrivilegios?.PED_COMPRAS_DETALHADO === "string";

  const permitirVisualizarMenuVendas =
    tipoUsuario === "MASTER" ||
    typeof listaPrivilegios?.PED_VENDAS === "string";

  const permitirVisualizarMenuConfiguracoes =
    tipoUsuario === "MASTER" ||
    typeof listaPrivilegios?.PED_CONFIGURACOES === "string";

  const filhosCompras = [
    {
      nome: "Finanças",
      filhos: [],
    },
  ];

  if (permitirVisualizarMenuCompras) {
    filhosCompras[0].filhos.push({
      nome: "Compras",
      permissoes: ["PED_COMPRAS_SUMARIZADO"],
      itemContratoWaychef: ["PEDIDO_COMPRA"],
      filhos: [
        {
          nome: "Pedido de compras",
          onClick: () => navigate("/financas/compras/pedido-compras"),
          permissoes: ["PED_COMPRAS_SUMARIZADO"],
        },
        {
          nome: "Recebimento de mercadorias",
          onClick: () => navigate("/financas/compras/mercadoria/recebimento"),
          permissoes: ["PED_COMPRAS_SUMARIZADO"],
        },
      ],
    });
  }

  if (permitirVisualizarMenuVendas) {
    filhosCompras[0].filhos.push({
      nome: "Vendas",
      permissoes: ["PED_VENDAS"],
      itemContratoWaychef: ["PEDIDO_VENDA"],
      filhos: [
        {
          nome: "Pedido de vendas",
          onClick: () => navigate("/financas/vendas/pedido-vendas"),
          permissoes: ["PED_VENDAS"],
        },
      ],
    });
  }

  if (permitirVisualizarMenuConfiguracoes) {
    filhosCompras[0].filhos.push({
      nome: "Configurações",
      onClick: () => navigate("/financas/compras/configuracoes"),
      permissoes: ["PED_CONFIGURACOES"],
    });
  }

  const homologacaoFormatada = homologacao
    ? true
    : baseURL.includes("192")
    ? true
    : false;

  const dadosDeFormaUtilizavel = {
    menus: [
      {
        nome: "Dashboard",
        icone: faChartPie,
        permissoes: ["CONS_DASHBOARD"],
        filhos: [
          {
            nome: "Novo",
            onClick: () => navigate("/dashboard/novo"),
          },
        ],
        // itemContratoWaybe: null,
        // permissoes: [],
        tipoEmpresa: [
          "PLATAFORMA",
          "INTEGRADOR",
          "FRANQUIA",
          "FRANQUEADO",
          "MATRIZ",
          "FILIAL",
        ],
      },
      {
        nome: "Cadastros",
        icone: faSquarePlus,
        tipoEmpresa: ["FRANQUIA", "FRANQUEADO", "MATRIZ", "FILIAL"],
        filhos: [
          {
            nome: "Wayservice",
            onClick: () => navigate("/produtos/cardapio/wayservice/listagem"),
            itemContratoWaychef: ["WAYSERVICE"],
          },
          {
            nome: "Produtos",
            itemContratoWaymenu: ["CARDAPIO_DIGITAL"],
            filhos: [
              {
                nome: "Cadastro",
                onClick: () => navigate("/produtos/cadastro/listagem"),
              },
              {
                nome: "Parâmetros",
                filhos: [
                  {
                    nome: "Grupos",
                    onClick: () => navigate("/produtos/parametros/grupos"),
                    permissoes: ["PROD_CAD_GRUPO"],
                  },
                  {
                    nome: "Menus",
                    onClick: () =>
                      navigate("/produtos/parametros/menus/listagem"),
                    permissoes: ["PROD_CAD_MENU"],
                  },
                  {
                    nome: "Observações",
                    onClick: () => navigate("/produtos/parametros/observacoes"),
                    permissoes: ["PROD_CAD_OBS"],
                  },
                  {
                    nome: "Variações",
                    onClick: () => navigate("/produtos/parametros/variacoes"),
                    permissoes: ["PROD_CAD_VARIACAO"],
                  },
                ],
              },
            ],
          },
          {
            nome: "Clientes",
            permissoes: ["PESSOA_CAD_CLI"],
            filhos: [
              {
                nome: "Cadastro",
                onClick: () => navigate("/clientes/cadastro/listagem"),
                itemContratoWaymenu: ["CARDAPIO_DIGITAL"],
                permissoes: ["PESSOA_CAD_CLI"],
              },
              {
                nome: "Configurações",
                onClick: () => navigate("/clientes/configuracoes"),
                permissoes: ["PESSOA_CAD_CLI"],
              },
              {
                nome: "Parâmetros",
                onClick: () => navigate("/clientes/parametros"),
                permissoes: ["PESSOA_CAD_CLASSIFICACAO"],
                filhos: [
                  {
                    nome: "Classificações",
                    onClick: () =>
                      navigate("/clientes/parametros/classificacoes"),
                    permissoes: ["PESSOA_CAD_CLASSIFICACAO"],
                  },
                ],
              },
            ],
          },
          {
            nome: "Fidelidade",
            permissoes: ["PARAM_CAD_CASHBACK"],
            itemContratoWaybe: ["CONTROLE_CASHBACK"],
            itemContratoWaychef: ["CONTROLE_CASHBACK"],
            filhos: [
              {
                nome: "Cashback",
                filhos: [
                  {
                    nome: "Cadastros",
                    onClick: () => navigate("/fidelidade/cashback"),
                  },
                  {
                    nome: "Configurações",
                    onClick: () =>
                      navigate("/fidelidade/cashback/configuracoes"),
                  },
                ],
              },
            ],
          },
          {
            nome: "Minha Empresa",
            itemContratoWaymenu: ["CARDAPIO_DIGITAL"],
            filhos: [
              {
                nome: "Funcionamento Empresa",
                onClick: () => navigate("/funcionamento-empresa"),
                itemContratoWaymenu: ["CARDAPIO_DIGITAL"],
              },
            ],
          },
          {
            nome: "Módulos",
            onClick: () => navigate("/parametros/modulos/listagem"),
            permissoes: ["CONF_CAD_MODULO"],
            itemContratoWaymenu: ["CARDAPIO_DIGITAL"],
          },

          {
            nome: "Waymenu",
            itemContratoWaymenu: ["CARDAPIO_DIGITAL"],
            filhos: [
              {
                nome: "Cardápio",
                onClick: () => navigate("/cardapio/listagem"),
              },
              {
                nome: "Entregas",
                onClick: () => navigate("/entregas"),
              },

              {
                nome: "Pedidos",
                onClick: () => navigate("/pedidos"),
              },
              {
                nome: "Banners",
                filhos: [
                  {
                    nome: "Cadastro",
                    onClick: () => navigate("/banners/cadastro"),
                  },
                ],
              },

              {
                nome: "Cupons",
                filhos: [
                  {
                    nome: "Cadastro",
                    onClick: () => navigate("/cupons/"),
                  },
                  // {
                  //   nome: "Dados principais",
                  //   onClick: () => navigate("/cupons/dados"),
                  // },
                ],
              },
              {
                nome: "Vantagens",
                filhos: [
                  {
                    nome: "Cadastro",
                    onClick: () => navigate("/vantagens/cadastro"),
                  },
                  {
                    nome: "Categorias",
                    onClick: () => navigate("/vantagens/categorias"),
                  },
                ],
              },
            ],
          },
        ],
      },
      // {
      //   nome: "Cadastros",
      //   icone: faSquarePlus,
      //   tipoEmpresa: ["PLATAFORMA", "INTEGRADOR"],
      //   filhos: [
      //     {
      //       nome: "Usuários",
      //       filhos: [
      //         {
      //           nome: "Usuários",
      //           onClick: () => navigate("/cadastros/usuarios/usuarios"),
      //         },
      //         {
      //           nome: "Perfis de usuários",
      //           onClick: () => navigate("/cadastros/usuarios/perfis-usuarios"),
      //         },
      //       ],
      //     },
      //   ],
      // },
      {
        nome: "Cardápio Digital",
        itemContratoWaymenu: ["CARDAPIO_DIGITAL"],
        icone: faUtensils,
        filhos: [
          {
            nome: "Cardápio",
            onClick: () => window.open("/waymenu/redirect/cardapio", "_blank"),
          },
          {
            nome: "Delivery",
            onClick: () => window.open("/waymenu/redirect/delivery", "_blank"),
          },
        ],
      },
      {
        nome: "Consultas Rápidas",
        icone: faSearch,
        tipoEmpresa: ["FRANQUIA", "FRANQUEADO", "MATRIZ", "FILIAL"],
        filhos: [
          {
            nome: "Atendimentos",
            permissoes: ["CONS_ATENDIMENTOS"],
            onClick: () => navigate("/consultas-rapidas/atendimentos"),
          },
          {
            nome: "Caixas",
            permissoes: ["CONS_CAIXA"],
            onClick: () => navigate("/consultas-rapidas/caixas"),
          },
        ],
      },
      {
        nome: "Operacional",
        icone: faUser,
        filhos: filhosCompras,
        tipoEmpresa: ["FRANQUIA", "FRANQUEADO", "MATRIZ", "FILIAL"],
      },
      {
        nome: "Relatórios",
        icone: faFileLines,
        tipoEmpresa: ["FRANQUIA", "FRANQUEADO", "MATRIZ", "FILIAL"],
        filhos: [
          {
            nome: "Cashback",
            itemContratoWaybe: ["CONTROLE_CASHBACK"],
            permissoes: ["PARAM_REL_CASHBACK"],
            onClick: () => navigate("/relatorios/cashback"),
          },
          {
            nome: "Cadastrais",
            filhos: [
              {
                nome: "Clientes",
                permissoes: ["REL_CAD_CLIENTE"],
                onClick: () =>
                  navigate("analise-relatorios/cadastrais/clientes"),
              },
              {
                nome: "Ficha técnica",
                permissoes: ["REL_CAD_FICHA_TECNICA"],
                onClick: () =>
                  navigate("/analise-relatorios/cadastrais/ficha-tecnica"),
              },
              {
                nome: "Produtos",
                onClick: () =>
                  navigate("analise-relatorios/cadastrais/produtos"),
                permissoes: ["REL_CAD_PRODUTO"],
              },
              {
                nome: "Tributação",
                permissoes: ["REL_CAD_TRIBUTACAO"],
                filhos: [
                  {
                    nome: "ICMS",
                    onClick: () =>
                      navigate(
                        "/analise-relatorios/cadastrais/tributacao/icms"
                      ),
                    permissoes: ["REL_CAD_TRIBUTACAO"],
                  },
                  {
                    nome: "PIS/COFINS",
                    onClick: () =>
                      navigate(
                        "/analise-relatorios/cadastrais/tributacao/piscofins"
                      ),
                    permissoes: ["REL_CAD_TRIBUTACAO"],
                  },
                ],
              },
            ],
          },
          {
            nome: "Franquia",
            tipoEmpresa: ["FRANQUIA"],
            permissoes: ["REL_FAT_FRANQUIA"],
            filhos: [
              {
                nome: "Rebate",
                onClick: () =>
                  navigate("/analise-relatorios/faturamento/franquia/rebate"),
                permissoes: ["REL_FAT_FRANQUIA"],
              },
            ],
          },
          {
            nome: "Fiscais",
            permissoes: ["REL_FAT_FISCAIS"],
            filhos: [
              {
                nome: "Listagem aux. Escrituração fiscal",
                onClick: () =>
                  navigate(
                    "/analise-relatorios/faturamento/fiscais/listagem-aux-escrituracao-fiscal"
                  ),
                permissoes: ["REL_FAT_FISCAIS"],
              },
            ],
          },
          {
            nome: "Estoque",
            permissoes: ["REL_ESTOQUE"],
            filhos: [
              {
                nome: "Em falta",
                onClick: () => navigate("/analise-relatorios/estoque/em-falta"),
                permissoes: ["REL_ESTOQUE"],
              },
              {
                nome: "Disponível",
                onClick: () =>
                  navigate("/analise-relatorios/estoque/disponivel"),
                permissoes: ["REL_ESTOQUE"],
              },
              {
                nome: "Histórico de movimentações",
                onClick: () =>
                  navigate(
                    "/analise-relatorios/estoque/historico-movimentacoes"
                  ),
                permissoes: ["REL_ESTOQUE"],
              },
              {
                nome: "Geral",
                onClick: () => navigate("/analise-relatorios/estoque/geral"),
                permissoes: ["REL_ESTOQUE"],
              },
              {
                nome: "Movimentações de insumos",
                onClick: () =>
                  navigate("/analise-relatorios/estoque/movimentacoes-insumos"),
                permissoes: ["REL_ESTOQUE"],
              },
            ],
          },
          {
            nome: "Faturamento",
            filhos: [
              {
                nome: "Cancelamentos",
                permissoes: ["REL_FAT_CANC"],
                filhos: [
                  {
                    nome: "Itens cancelados",
                    onClick: () =>
                      navigate(
                        "/analise-relatorios/faturamento/cancelamentos/itens-cancelados"
                      ),
                    permissoes: ["REL_FAT_CANC"],
                  },
                ],
              },
              {
                nome: "Produtos Vendidos",
                permissoes: ["REL_FAT_PROD_VENDIDOS"],
                filhos: [
                  {
                    nome: "Top 10",
                    onClick: () =>
                      navigate("/analise-relatorios/produtos-vendidos/top10"),
                    permissoes: ["REL_FAT_PROD_VENDIDOS"],
                  },
                  {
                    nome: "Por tabela de preço",
                    onClick: () =>
                      navigate(
                        "/analise-relatorios/faturamento/produtos-vendidos/tabela-preco"
                      ),
                    permissoes: ["REL_FAT_PROD_VENDIDOS"],
                  },
                  {
                    nome: "Por pacote",
                    onClick: () =>
                      navigate(
                        "/analise-relatorios/faturamento/produtos-vendidos/por-pacote"
                      ),
                    permissoes: ["REL_FAT_PROD_VENDIDOS"],
                  },
                  {
                    nome: "Por grupo",
                    onClick: () =>
                      navigate(
                        "/analise-relatorios/faturamento/produtos-vendidos/por-grupo"
                      ),
                    permissoes: ["REL_FAT_PROD_VENDIDOS"],
                  },
                  {
                    nome: "Itens vendidos",
                    onClick: () =>
                      navigate(
                        "/analise-relatorios/faturamento/produtos-vendidos/itens-vendidos"
                      ),
                    permissoes: ["REL_FAT_PROD_VENDIDOS"],
                  },
                  {
                    nome: "Por período",
                    permissoes: ["REL_FAT_MONETARIO"],
                    onClick: () =>
                      navigate(
                        "/analise-relatorios/faturamento/produtos-vendidos/por-periodo"
                      ),
                    permissoes: ["REL_FAT_PROD_VENDIDOS"],
                  },
                  {
                    nome: "Por vendedor",
                    permissoes: ["REL_FAT_MONETARIO"],
                    onClick: () =>
                      navigate(
                        "/analise-relatorios/faturamento/produtos-vendidos/por-vendedor"
                      ),
                    permissoes: ["REL_FAT_PROD_VENDIDOS"],
                  },
                ],
              },
              {
                nome: "Módulos",
                permissoes: ["REL_FAT_MODULOS"],
                filhos: [
                  {
                    nome: "Delivery",
                    onClick: () =>
                      navigate(
                        "/analise-relatorios/faturamento/modulos/delivery"
                      ),
                    permissoes: ["REL_FAT_MODULOS"],
                  },
                  {
                    nome: "Controle de tempo produção",
                    onClick: () =>
                      navigate(
                        "/analise-relatorios/faturamento/modulos/controle-tempo-de-producao"
                      ),
                    permissoes: ["REL_FAT_MODULOS"],
                  },
                ],
              },
              {
                nome: "Monetário",
                permissoes: ["REL_FAT_MONETARIO"],
                filhos: [
                  {
                    nome: "Anual (mês a mês)",
                    onClick: () =>
                      navigate(
                        "/analise-relatorios/faturamento/monetario/anual"
                      ),
                    permissoes: ["REL_FAT_MONETARIO"],
                  },
                  {
                    nome: "Atendimentos",
                    onClick: () =>
                      navigate(
                        "/analise-relatorios/faturamento/monetario/atendimentos"
                      ),
                    permissoes: ["REL_FAT_MONETARIO"],
                  },
                  {
                    nome: "Taxas de serviço",
                    onClick: () =>
                      navigate(
                        "/analise-relatorios/faturamento/monetario/taxas-servico"
                      ),
                    permissoes: ["REL_FAT_MONETARIO"],
                  },
                  {
                    nome: "Comissão do vendedor",
                    onClick: () =>
                      navigate(
                        "/analise-relatorios/faturamento/monetario/comissao-vendedor"
                      ),
                    permissoes: ["REL_FAT_MONETARIO"],
                  },
                  {
                    nome: "Faturamento por período",
                    onClick: () =>
                      navigate(
                        "/analise-relatorios/faturamento/monetario/por-periodo"
                      ),
                    permissoes: ["REL_FAT_MONETARIO"],
                  },
                  {
                    nome: "Por módulo",
                    onClick: () =>
                      navigate(
                        "/analise-relatorios/faturamento/monetario/por-modulo"
                      ),
                    permissoes: ["REL_FAT_MONETARIO"],
                  },
                  {
                    nome: "Formas de pagamento",
                    onClick: () =>
                      navigate(
                        "/analise-relatorios/faturamento/monetario/formas-de-pagamento"
                      ),
                    permissoes: ["REL_FAT_MONETARIO"],
                  },
                ],
              },
            ],
          },
          {
            nome: "Finanças",
            permissoes: ["REL_FINANCAS"],
            filhos: [
              {
                nome: "Contas a Pagar",
                permissoes: ["FIN_CAD_TIT_PAGAR", "REL_FINANCAS"],
                onClick: () =>
                  navigate("/analise-relatorios/financas/contas-a-pagar"),
              },
              {
                nome: "Contas a Receber",
                permissoes: ["FIN_CAD_TIT_RECEBER", "REL_FINANCAS"],
                onClick: () =>
                  navigate("/analise-relatorios/financas/contas-a-receber"),
              },
            ],
          },
        ],
      },
      {
        nome: "Contrato",
        icone: faFileContract,
        tipoEmpresa: ["PLATAFORMA", "INTEGRADOR"],
        permissoes: ["PLAT_CONTRATOS"],
        filhos: [
          {
            nome: "Waychef",
            tipoEmpresa: ["PLATAFORMA", "INTEGRADOR"],
            filhos: [
              {
                nome: "Planos padrão",
                tipoEmpresa: ["PLATAFORMA"],
                onClick: () => navigate("/contrato/plataforma/waychef/planos"),
              },
              {
                nome: "Planos",
                tipoEmpresa: ["INTEGRADOR"],
                onClick: () => navigate("/contrato/integrador/waychef/planos"),
              },
            ],
          },
          {
            nome: "Waymenu",
            tipoEmpresa: ["PLATAFORMA", "INTEGRADOR"],
            filhos: [
              {
                nome: "Planos padrão",
                tipoEmpresa: ["PLATAFORMA"],

                onClick: () => navigate("/contrato/plataforma/waymenu/planos"),
              },
              {
                nome: "Planos",
                tipoEmpresa: ["INTEGRADOR"],

                onClick: () => navigate("/contrato/integrador/waymenu/planos"),
              },
            ],
          },
          {
            nome: "Extras",
            tipoEmpresa: ["INTEGRADOR"],
            permissoes: ["PLAT_CONTRATOS_EXTRAS"],
            onClick: () => navigate("contrato/integrador/extras"),
          },
          {
            nome: "Extras padrão",
            tipoEmpresa: ["PLATAFORMA"],

            onClick: () => navigate("contrato/plataforma/extras"),
          },
          {
            nome: "Controle de Contratos",
            tipoEmpresa: ["PLATAFORMA", "INTEGRADOR"],
            onClick: () => navigate("/empresas/controle-contratos"),
          },
        ],
      },
      {
        nome: "Relatórios",
        icone: faFileContract,
        tipoEmpresa: ["PLATAFORMA", "INTEGRADOR"],
        permissoes: ["PLAT_CONTRATOS"],
        filhos: [
          {
            nome: "Transações",
            tipoEmpresa: ["PLATAFORMA", "INTEGRADOR"],
            onClick: () => navigate("/analise-relatorios/transacoes"),
          },
          {
            nome: "Faturamento Integrador",
            tipoEmpresa: ["PLATAFORMA"],
            onClick: () =>
              navigate("/analise-relatorios/faturamento-integradores"),
          },
          {
            nome: "Auditoria Itens",
            tipoEmpresa: ["PLATAFORMA", "INTEGRADOR"],
            onClick: () => navigate("/analise-relatorios/auditoria-itens"),
          },
        ],
      },
      {
        nome: "Waymenu",
        tipoEmpresa: ["PLATAFORMA"],
        icone: faUtensils,
        filhos: [
          {
            nome: "Campanhas",
            onClick: () => navigate("/campanhas"),
            tipoEmpresa: ["PLATAFORMA"],
          },
        ],
      },

      {
        nome: "Serviços",
        icone: faLaptop,
        tipoEmpresa: ["FRANQUIA", "FRANQUEADO", "MATRIZ", "FILIAL"],
        filhos: [
          {
            nome: "Serviços do Sistema",
            permissoes: ["PARAM_SERVICOS_SISTEMA"],
            onClick: () => navigate("/servicos/servicosdosistema"),
          },
        ],
      },
    ],
  };

  const [listaMenu, setListaMenuUtilizavel] = useState(dadosDeFormaUtilizavel);
  const [loadingMenu, setLoadingMenu] = useState(true);
  const [headerFixo, setHeaderFixo] = useState(false);
  const [carregandoDashboard, setCarregandoDashboard] = useState(true);
  const [dadosEmpresa, setDadosEmpresa] = useState({});
  const [dadosUsuario, setDadosUsuario] = useState({});
  function atualizarDashboards(lista) {
    try {
      let novoMenu = listaMenu;
      novoMenu.menus["Dashboard"].filhos = lista;
      setListaMenu(novoMenu);
    } catch (e) {}
  }
  const [listaDashboards, setListaDashboards] = useState();

  function atualizarHeaderFixo(status) {
    try {
      setHeaderFixo(status);
    } catch (e) {}
  }

  useEffect(() => {
    if (sessao) {
      setListaMenuUtilizavel(listaMenu);

      setTimeout(() => {
        buscarDashboardsStorage();
      }, 500);
    }
  }, []);

  useEffect(() => {
    if (sessao) {
      setListaMenuUtilizavel(listaMenu);
    }
  }, [homologacao]);

  // function atualizarHeaderFixo(status) {
  //   try {
  //     setHeaderFixo(status);
  //   } catch (e) { }
  // }

  function buscarDashboards(tokenAtual = token, montarMenu = true) {
    return new Promise(async (resolve, reject) => {
      let api = axios.create({
        baseURL: baseURL,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Authorization": `Bearer ${tokenAtual}`,
        },
      });
      await api
        .get("/relatorios/dashboard")
        .then((dados) => {
          localStorage.setItem("lista_dashboards", JSON.stringify(dados.data));

          //Verificar se deve montar menu, ou está apenas atualizando lista
          if (montarMenu) {
            buscarDashboardsStorage();
          }
          resolve(dados);
        })
        .catch((error) => {
          console.log(error);
          resolve([]);
        });
    });
  }

  async function buscarDashboardsStorage() {
    try {
      //verificar se existem itens no localstorage, caso nao existir buscar
      const dashboards = localStorage.getItem("lista_dashboards");
      if (dashboards == null || dashboards == '""') {
        //buscarDashboards();
        return;
      }

      montarDashboards(JSON.parse(dashboards));
    } catch (e) {
      //buscarDashboards();
    }
  }

  const montarDashboards = (lista) => {
    try {
      let dashboards = { ...dadosDeFormaUtilizavel };

      const indexDashboard = dashboards.menus.findIndex(
        (item) => item.nome === "Dashboard"
      );
      if (typeof lista === "object") {
        lista.map((item) => {
          dashboards.menus[indexDashboard].filhos.unshift({
            nome: item.nome,
            onClick: () => {
              navigate(`/dashboard/${item.urlAmigavel}`);
              navigate(0);
            },
          });
          // [item.nome] = () => {
          //   navigate(`/dashboard/${item.urlAmigavel}`);
          //   navigate(0);
          // };
        });
      }

      // dashboards["Novo"] = () => {
      //   navigate("/dashboard/novo");
      // };

      // setListaDashboards(dashboards);
      setListaMenuUtilizavel(dashboards);
    } catch (e) {
      console.log(e, "error");
      // buscarDashboards();
    }
  };

  const buscarDadosEmpresaStorage = async () => {
    try {
      setDadosEmpresa({});
      setDadosUsuario({});
      setDadosEmpresa(JSON.parse(localStorage.getItem("_da")).empresaDTO);
      setDadosUsuario(JSON.parse(localStorage.getItem("_da")).usuarioLogadoDTO);
    } catch (error) {
      toast.error("Não foi possivel carregar os dados de sua empresa!");
    }
  };

  return (
    <MenuContext.Provider
      value={{
        headerFixo,
        listaMenu,
        atualizarHeaderFixo,
        buscarDashboards,
        setCarregandoDashboard,
        carregandoDashboard,
        buscarDashboardsStorage,
        dadosEmpresa,
        buscarDadosEmpresaStorage,
        dadosUsuario,
        listaDashboards,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};

export const useMenu = () => useContext(MenuContext);
