import styled from '@emotion/styled';
import { Cell, Row } from '@table-library/react-table-library';
import { Columns, Minus, Plus } from 'phosphor-react';
import Popup from 'reactjs-popup';

import { Autocomplete, TextField } from '@mui/material';
import { CampoDeSelecao } from '../CampoInput';

export const Stylede = styled(Row)`
  :hover {
    background-color: red;
  }
  color: red;
`;

export const StyledCell = styled(Cell)`
  background-color: red;
`;

export const StyledContainerGlobal = styled.div`
  table {
    z-index: 0 !important;
  }
  .blurTabela {
    > table {
      opacity: 0 !important;
    }
  }
  width: 100%;
  th {
    background-color: ${(props) => props.theme.cores.brand10brand80};
    padding: 16px;
    font-family: 'Comfortaa', sans-serif;
    font-size: 15px;
    color: ${(props) => props.theme.adaptativo('netral10', 'neutral100')};
    border: none;
    word-break: break-all;

    .resizer-handle {
      background-color: ${(props) => props.theme.cores.textoSimples};
    }
    align-items: center;
    word-break: break-word;
    > div > div > div {
      white-space: normal;
      line-height: 1.5;
    }
    > div {
      white-space: normal;
      min-height: 22.5px;
    }
  }

  tr {
    &:nth-child(even) {
      background-color: ${(props) =>
    props.theme.adaptativo('surface3', 'surfaceBackground1')};
    }
    &:nth-child(odd) {
      background-color: transparent;
    }

    &:hover {
      background-color: ${(props) =>
    props.hoverLinhas ? props.theme.cores.hoverTabela : null};
    }

    > td > div > input {
      height: 17px;
      width: 17px;
    }

    > th > div > input {
      height: 17px;
      width: 17px;
    }
  }

  td {
    min-width: 100%;
    font-size: 15px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;

    color: ${(props) => props.theme.adaptativo('neutral10', 'neutral100')};

    padding: 16px 16px;
    text-align: left;

    svg {
      fill: ${(props) => props.theme.adaptativo('neutral10', 'neutral100')};
    }

    div {
      white-space: pre-wrap !important;
      text-overflow: unset !important;
      word-break: break-word !important;
    }
  }

  tfoot > tr > th {
    font-family: 'Source Sans Pro', sans-serif;
    background-color: transparent;
    color: ${(props) => props.theme.adaptativo('brand70', 'neutral100')};
    font-weight: 700;
  }

  .css-ksz69i-getRowContainerStyle-Row:not(:last-of-type) > .td {
    border: none;
  }
`;

export const ContainerTabela = styled.div`
  /* overflow: auto; */

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) =>
    theme.adaptativo('brand40Default', 'brand80')};
    border-radius: 5px;
  }

  table::-webkit-scrollbar {
    width: 4px;
    height: 6px;
    display: block;
    background-color: ${(props) => props.theme.cores.brand20};
    border-radius: 10px;
  }

  table::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.cores.actionActive};
    width: 10px;

    border-radius: 10px;
  }
`;

export const ContainerSemDados = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 400px;
  width: 100%;

  p {
    font-size: 22px;
    font-family: monospace;
  }
`;

export const ContainerPaginacao = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 6px 14px;

  background-color: ${(props) => props.theme.cores.brand10brand80};

  @media (max-width: 768px) {
    justify-content: center;
  }
`;
export const ContainerPaginas = styled.span`
  display: flex;
  align-items: center;
  gap: 10px;

  @media (max-width: 768px) {
    gap: 0px;
  }
`;

export const BotaoPaginacao = styled.button`
  font-size: 17px;
  font-family: 'Source Sans Pro', sans-serif;
  line-height: 21px;
  padding: 8px;

  cursor: pointer;

  color: ${(props) => props.theme.adaptativo('brand80', 'neutral100')};

  border: none;
  background-color: transparent;
`;

export const ContainerPaginaAtualTextfield = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const TextFieldIcon = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-left: -35px;
    margin-bottom: -10px;
  }
`;
export const FiltragemTabela = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  width: 100%;

  background-color: ${(props) => props.theme.cores.brand10brand80};

  /* border-bottom: 1px solid ${(props) => props.theme.cores.corHr}; */

  @media (max-width: 840px) {
    flex-direction: column;
    gap: 24px;
  }
`;

export const BotoesIcone = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;

  svg {
    color: ${(props) => props.theme.cores.actionActive};
    height: 16px;
    width: 16px;
  }

  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: end;
  }

  ${(props) => props.larguraTotal && `width: 100%`}
`;

export const Botoes = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;

  @media (max-width: 768px) {
    gap: 16px;
  }

  @media (max-width: 420px) {
    flex-direction: column;
  }
`;

export const BotaoAzulRefatorado = styled.button`
  cursor: pointer;
  border: none;
  display: flex;

  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;

  width: 151px;
  height: 40px;
  border-radius: 8px;
  font-weight: 700;

  background-color: transparent;

  padding: 8px 24px;

  font-size: 16px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  line-height: 1.5;

  color: ${(props) => props.theme.cores.neutral100};
  fill: ${(props) => props.theme.cores.neutral100brand80};
  background-color: ${(props) => props.theme.cores.actionActive};
  border: ${(props) => `1px solid ${props.theme.cores.actionActive}`};

  &:hover {
    background-color: ${(props) =>
    props.theme.temaDarkAtivo
      ? props.theme.cores.actionHover
      : props.theme.cores.brand40Default};
    color: ${(props) => props.theme.cores.neutral10};
    fill: ${(props) => props.theme.cores.brand80};
    border: ${(props) => `1px solid ${props.theme.cores.brand40Default}`};
    transition: color 0.3s ease-in-out, border 0.3s ease-in-out,
      background-color 0.3s ease-in-out;
  }

  &:active {
    background-color: ${(props) => props.theme.cores.actionPressed};
    color: ${(props) => props.theme.cores.textoInverso};
    fill: ${(props) => props.theme.cores.textoInverso};
    border: ${(props) => `1px solid ${props.theme.cores.actionActive}`};
  }
`;

export const IconeColunas = styled(Columns)`
  display: flex;
  align-items: center;
`;

export const StyledTabela = styled.table`
  width: 100%;
  border-collapse: collapse;
  display: grid;
`;

export const StyledPopupDownload = styled(Popup)`
  &-overlay {
    background-color: ${(props) =>
    props.theme.adaptativo('actionDisable', 'surface2')};
  }

  &-content {
    max-width: 60px;
    background-color: ${(props) =>
    props.theme.adaptativo('actionDisable', 'surface2')};
    border: none;

    & .popup-arrow {
      color: ${(props) => props.theme.adaptativo('actionDisable', 'surface2')};
      stroke: ${(props) => props.theme.adaptativo('actionDisable', 'surface2')};
    }
  }
`;

export const ContainerBotoesPopup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  button:nth-child(1) {
    border-bottom: 1px solid
      ${(props) => props.theme.adaptativo('bordaCheckbox', 'textActionDisable')};
    padding-bottom: 5px;
  }

  button:nth-child(2) {
    padding-top: 5px;
  }
`;
export const BotaoPopup = styled.button`
  border: none;
  background-color: transparent;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Source Sans Pro', sans-serif;
  cursor: pointer;

  color: ${(props) => props.theme.cores.textoSimples};
  &:hover {
    opacity: 0.7;
  }
`;

export const StyledPopupColunas = styled(Popup)`
  &-content {
    padding: 8px;
    background-color: ${(props) =>
    props.theme.adaptativo('actionDisable', 'surface2')};
    border: none;
    max-height: 220px;
    overflow-y: auto;

    &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) =>
    theme.adaptativo('brand40Default', 'brand80')};
    border-radius: 5px;
  }

    &::-webkit-scrollbar {
      width: 3px;
      height: 6px;
      display: block;
      background-color: ${(props) => props.theme.cores.brand20};
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${(props) => props.theme.cores.actionActive};
      width: 5px;

      border-radius: 5px;
    }

    & .popup-arrow {
      color: ${(props) => props.theme.adaptativo('actionDisable', 'surface2')};
      stroke: ${(props) => props.theme.adaptativo('actionDisable', 'surface2')};
    }
  }
`;

export const ContainerCheckbox = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    padding: 8px 0;
    font-size: 15px;
    font-weight: 400px;
  }
`;

export const StyledHr = styled.hr`
  border-color: ${(props) =>
    props.theme.adaptativo('actionDisable', 'neutral40')};
`;

export const BotaoPrimario = styled.button`
  color: ${({ accent = '#FFFFFF' }) => accent};
  background-color: ${({ color = '#000000' }) => color};
  box-shadow: none;
  border: none;
  cursor: pointer;
  border-radius: 4px;
`;

export const BotaoPagina = styled(BotaoPrimario)`
  font-size: ${({ selected }) => (selected ? '19px' : '17px')};
  font-weight: ${({ selected }) => (selected ? '600' : '400')};
  width: 32px;
  height: 32px;
  color: ${({ theme }) => theme.cores.textoSimples};
  font-family: 'Source Sans Pro', sans-serif;

  /* @media (max-width: 768px) {
    width: 25px;
    height: 25px;
  } */
`;

export const StyledTextFieldSelect = styled(TextField)`
  margin-top: 24px;
  width: 100%;
  /* margin-right: 32px; */

  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  .gWGBob.MuiSelect-select {
    height: 27px;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0px;
  }

  label {
    padding-left: 5px;
  }
`;

export const StyledTextFieldAutocomplete = styled(TextField)`
  width: 100%;
  height: 56px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  label {
    padding-left: ${(props) =>
    props.value != "" || props.value != null ? `16px;` : "12px;"};
    padding-top: ${(props) =>
    props.value != "" && props.value != null && `8px;`};
  }

  input {
    padding-left: 2px;
    padding-top: 19px;
  }

  &:has(input:focus) label {
    padding-top: 7px;
    padding-left: 16px;
  }

  div {
    height: 56px;
  }

  .Mui-disabled {
    cursor: ${({ typeNumber }) => typeNumber === false && 'not-allowed'};
    opacity: ${({ typeNumber }) => (typeNumber === true ? 1 : 0.7)};
  }

  .MuiAutocomplete-input {
    padding: 7.5px 5px 10px 5px !important;
  }
  .Mui-focused {
    z-index: 99;
    position: absolute;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .MuiFilledInput-input {
    &:has(input:focus) label {
      padding-top: 0px;
      padding-left: 56px;
    }
  }
`;

export const ContainerExpansaoTabela = styled.div`
  div {
    &:nth-child(even) {
      background-color: ${(props) =>
    props.theme.adaptativo('surface3', 'surfaceBackground1')};
    }
    &:nth-child(odd) {
      background-color: transparent;
    }
  }
`;

export const StyledCampoDeSelecao = styled(CampoDeSelecao)`
  &.MuiInputBase-root {
    height: 0;
  }
`;

export const StyledPlus = styled(Plus)`
  color: ${(props) => props.theme.cores.actionActive};

  cursor: pointer;
`;

export const StyledMinus = styled(Minus)`
  color: ${(props) => props.theme.cores.actionActive};

  cursor: pointer;
`;

export const AccordionCard = styled.div`
  border: 1px solid ${(props) => props.theme.cores.brand50};

  > div {
    padding: 24px;
  }

  > div > h1 {
    font-size: 23px;
    font-weight: 500;
  }
`;

export const CardTabelaPreco = styled.div`
  display: flex;
  flex-direction: column;

  gap: 8px;
  align-items: start;
  width: 167px;
  padding: 8px 24px;

  border-radius: 16px;
  background-color: ${(props) =>
    props.theme.adaptativo('corCardRelatorioClientes', 'brand70')};

  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.16);

  > div {
    display: flex;
  }

  > div:first-child p {
    font-size: 19px;
    font-weight: 600;
    font-family: 'Source Sans Pro', sans-serif;
    color: ${(props) => props.theme.adaptativo('brand50', 'brand20')};
  }
`;

export const ContainerCards = styled.div`
  display: flex;
  gap: 40px;

  margin-top: 24px;
`;

export const ContainerTabelaSecundaria = styled.div`
  border: 1px solid ${(props) => props.theme.adaptativo('brand50', 'brand30')};
`;

export const ContainerTextoTabelaSecundaria = styled.div`
  padding: 1rem;

  background-color: ${(props) => props.theme.cores.brand10brand80};

  > p {
    color: ${(props) => props.theme.adaptativo('brand80', 'neutral100')};
    font-family: 'Comfortaa', sans-serif;
    font-weight: 600;
  }
`;

export const TextoFiltroSemResultado = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  color: ${(props) => props.theme.adaptativo('brand80', 'neutral100')};
  font-family: 'Comfortaa', sans-serif;
  font-size: 19px;
  font-weight: 600;
`;

export const IconsFirstAndLastPage = styled.span`
  font-size: 24px;

  display: flex;
  align-items: center;

  color: ${(props) => props.theme.cores.actionActive};
`;

export const IconeBotaoPage = styled.button`
  cursor: pointer;
  border: none;
  background: transparent;

  &:disabled {
    opacity: 0.7;
    cursor: auto;
    span,
    svg {
      color: ${(props) => props.theme.cores.neutral70};
    }
  }
`;

export const TextoHeaderTabela = styled.p`
  font-family: 'Comfortaa';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.15px;
  color: ${({ theme }) => theme.adaptativo('neutral10', 'neutral100')};
  margin-top: 24px;
`;

export const StyledAutocomplete = styled(Autocomplete)`
  width: 100%;
  height: 56px;

  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  label {
    padding-left: ${(props) =>
    props.value != "" || props.value != null ? `12px;` : "16px;"};
    ${(props) =>
    props.value != "" || props.value != null
      ? "padding-top: 7px;"
      : `padding-top: 2px`}
  }

  input {
    padding-left: 12px;
    padding-top: 19px;
  }

  button {
    margin-left: 10px;
  }
  button svg {
    margin-left: 0;
    margin-bottom: 0;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
    border-bottom: 1px solid
      ${(props) => props.theme.adaptativo('neutral50', 'neutral100')};

    border-radius: 0;
  }

  .MuiInputLabel-root {
    padding-left: 0px;
    padding-bottom: 7px;
  }

  .MuiInputLabel-shrink {
    padding-top: 15px;
  }

  .MuiAutocomplete-root label {
    padding-top: 15px;
  }

  .MuiAutocomplete-tag {
    margin-top: 10px;
  }

  .MuiInputBase-root {
    background-color: ${({ theme }) =>
    theme.temaDarkAtivo
      ? String(theme.cores.neutral100).replace("1)", "0.2)")
      : "transparent"};

    padding: 13px 10px 5px;
  }

  .MuiAutocomplete-root:has(input:focus) label {
    padding-top: 15px;
  }

  .MuiAutocomplete-input {
    padding: 13px 4px 2px 5px !important;
  }

  .MuiAutocomplete-endAdornment {
    margin-top: 14px;
  }

  .MuiChip-root {
    margin: 6px 0 0;
  }
`;

export const CustomizacaoCheckbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 32px;
  min-width: 32px;

  margin-top: 16px;
  gap: 8px;
  svg {
    margin-left: 0;
    margin-bottom: 0;
    margin-right: 0;
  }
`;